import './App.css';
import Form from './Components/Common/form'
import Api from './Components/Api'
import Home from './Components/Home';
import Welcome from './Components/Welcome';
import getAllTodos from './Components/Todos';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { 
  Routes,
  Route,
  useNavigate
} from 'react-router-dom'
import { app } from './firebase-config';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function App() {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  let navigate = useNavigate();

  const handleAction = (id) => {
    const authentication = getAuth();

    if (id === 1) {
      signInWithEmailAndPassword(authentication, email, password)
        .then((response) => {
          navigate('/home')
          sessionStorage.setItem('Auth Token', response._tokenResponse.refreshToken)
        })
        .catch((error) => {
          if(error.code === 'auth/wrong-password'){
            toast.error('Please check the Password');
          }
          if(error.code === 'auth/user-not-found'){
            toast.error('Please check the Email');
          }
          if(error.code === 'auth/invalid-email'){
            toast.error('Geçersiz Mail Adresi');
          }
        })
    }

    if (id === 2) {
      console.log(password)
      createUserWithEmailAndPassword(authentication, email, password)
        .then((response) => {
          sessionStorage.setItem('Auth Token', response._tokenResponse.refreshToken);
          navigate('/home')
      })
      .catch((error) => {
        if(error.code === 'auth/invalid-email'){
          toast.error('Geçersiz Mail Adresi');
        }
        if(error.code === 'auth/email-already-in-use'){
          toast.error('Email Already in Use');
        }
      })
   }
  }

  useEffect(() => {
    let authToken = sessionStorage.getItem('Auth Token')

    if (authToken) {
      navigate('/home')
    }
  }, [])

  return (
    
    
      <div className="App">
        <>
          <ToastContainer />
          <Routes>
             <Route path='/' element={<Welcome title="Login"
            />} />
            <Route path='/login' element={<Form title="Login"  
            setEmail={setEmail}
            setPassword={setPassword}
            handleAction={() => handleAction(1)}
            />} />
            <Route path='/register' element={<Form title="Register" 
            setEmail={setEmail}
            setPassword={setPassword}
            handleAction={() => handleAction(2)}
            />} />
            <Route path='/home'
            element={
              <Home />}
          />
          </Routes>
        </>
      </div>
    
  );
}

export default App;


